import React, { useState } from "react";
import { useApi } from "../core/contexts/ApiProvider";
import { Link } from "react-router-dom";
import { useCart } from "../core/contexts/CartProvider";

export default function CartItems({ checkout, setCheckout }) {
  const api = useApi();
  const cart = useCart();
  const [cartItems, setCartItems] = useState(cart.getCart());

  const handleChange = (index) => (event) => {
    let updatedCartItems = cartItems;
    if (event.target.value == 0) {
      updatedCartItems[index].quantity = 1;
    } else {
      updatedCartItems[index].quantity = event.target.value;
    }
    setCartItems([...updatedCartItems]);
    cart.updateCart(index, event.target.value);
  };

  const getTotal = () => {
    return cartItems.reduce((a, b) => {
      return a + b.quantity * b.product.price;
    }, 0);
  };

  const removeItem = (index) => (event) => {
    let updatedCartItems = cart.removeItem(index);
    if (updatedCartItems.length == 0) {
      setCheckout(false);
    }
    setCartItems(updatedCartItems);
  };

  const openCheckout = () => {
    setCheckout(true);
  };

  return (
    <div>
      {cartItems.length > 0 ? (
        <div>
          {cartItems.map((item, i) => (
            <div
              className="hstack gap-3 py-3 text-decoration-none border-bottom"
              key={i}
              to={`/shops/${item.product._id}`}
            >
              <img
                className="border object-cover rounded"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                key={item?.product?.file?.name}
                src={`${
                  !item?.product?.file
                    ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                    : `${process.env.REACT_APP_BACKEND_URL}/image/${item?.product?.files[0]?.name}`
                }`}
              />
              <div className="w-50">
                <h3 className="text-body-primary">{item?.product?.name}</h3>
                {/* <p className="text-muted">{item?.product?.description}</p> */}
                <h4 className="text-muted">{item?.product?.price} F.CFA</h4>
              </div>
              <div className="ms-auto">
                <input
                  type="number"
                  value={item.quantity}
                  onChange={handleChange(i)}
                  className="form-control"
                />
              </div>
              <div className="ms-auto">
                <span className="fs-4">
                  {item.product.price * item.quantity} F.CFA
                </span>
              </div>
              <button
                className="ms-auto btn btn-primary"
                onClick={removeItem(i)}
              >
                <i class="bi bi-trash fs-4"></i>
              </button>
            </div>
          ))}
          <div className="hstack gap-3 justify-content-end py-4">
            <span className="text-danger fs-4">Total: ${getTotal()}</span>
            {!checkout &&
              (api.isAuthenticated() ? (
                <button className="btn btn-primary" onClick={openCheckout}>
                  Commander
                </button>
              ) : (
                <Link className="btn btn-light" to="/signin">
                  Connecter vous pour commander
                </Link>
              ))}
            <Link className="btn btn-secondary" to="/">
              Continuer votre Shopping
            </Link>
          </div>
        </div>
      ) : (
        <div class="container py-5 mb-lg-3">
          <div class="row justify-content-center pt-lg-4 text-center">
            <div class="col-lg-5 col-md-7 col-sm-9">
              <i class="bi bi-cart-x-fill fs-1 text-primary py-3"></i>
              <h2 class="h3 mb-4 text-primary">
                Aucun article ajouté à votre panier.
              </h2>
            </div>
            <Link className="btn btn-light" to="/">
              Continuer votre Shopping
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
