import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useUser } from "../core/contexts/UserProvider";
import Spinner from "../core/components/Spinner";
import { useFlash } from "../core/contexts/FlashProvider";
import AddToCart from "../cart/AddToCart";
import { getFormattedNumber } from "../core/helpers/Utility";
import Comments from "./Comments";

export default function SingleProduct() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const flash = useFlash();
  const api = useApi();
  const [product, setProduct] = useState();
  const [related, setRelated] = useState();
  const imgRef = useRef();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/products/${productId}`);
      if (response.success) {
        setProduct(response.product);
      } else {
        flash(response.error.message, "danger");
      }
    })();
  }, [api, productId]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/products/related/${productId}`);
      if (response.success) {
        setRelated(response.products);
      }
    })();
  }, [api]);

  const [values, setValues] = useState({
    comments: product?.comments || [],
  });

  const updateComments = (comments) => {
    setProduct({
      ...product,
      comments: comments,
    });
  };

  const handleClick = (file) => {
    imgRef.current.src = `${process.env.REACT_APP_BACKEND_URL}/image/${file?.name}`;
  };

  return (
    <>
      <section>
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {product?.name}
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section>
        <div className="container single-product">
          {!product && <Spinner />}
          <div className="row row-cols-sm-1 row-cols-md-3 g-5">
            <div className="col">
              <div className="gallery">
                <div className="image-principal">
                  <img
                    alt="Image"
                    ref={imgRef}
                    src={`${
                      !product?.files
                        ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${product?.files[0]?.name}`
                    }`}
                  />
                </div>
                <div className="image-list">
                  {product?.files.map((file, index) => (
                    <div
                      className="img-container"
                      onClick={() => handleClick(file)}
                    >
                      <img
                        key={index}
                        className="image-list-item"
                        alt="Image"
                        src={`${
                          !file
                            ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                            : `${process.env.REACT_APP_BACKEND_URL}/image/${file?.name}`
                        }`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col vstack">
              <div className="product-seller">{product?.shop?.name}</div>
              <h1 className="product-title">{product?.name}</h1>
              <h1 className="product-brands">{product?.brand?.name}</h1>
              <div className="product-category">{product?.category?.name}</div>
              <div className="product-price">
                <h2>{getFormattedNumber({ value: product?.price })}</h2>
              </div>
              <div className="py-2">
                {product?.quantity > 0 ? (
                  <span className="text-primary">DISPONIBLE</span>
                ) : (
                  <span></span>
                )}
              </div>
              <small>+ livraison gratuite à Abidjan</small>
              <div className="py-2">
                <i class="bi bi-star-fill fs-4 text-primary"></i> 9.0
              </div>
              {/* Options */}
              <div></div>
              <div className="py-4">
                <AddToCart product={product} />
              </div>
              {/* PARTAGE SUR LES RÉZO */}
              <div className="hstack gap-2">
                <h5>Partager sur les rezos</h5>
                <button className="btn btn-light">
                  <i class="bi bi-facebook fs-4"></i>
                </button>
                <button className="btn btn-light">
                  <i class="bi bi-whatsapp fs-4"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {related && related.length > 0 ? (
        <section>
          <div className="container">
            <h2>Produits connexes</h2>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 g-3">
              {related?.map((product, i) => (
                <div className="col" key={i}>
                  <Link to={`/products/${product?._id}`} className="product">
                    <div className="product-image">
                      <img
                        class="image"
                        alt="sliderImage"
                        src="/assets/no-image.png"
                      />
                    </div>
                    <div className="product-description">
                      <div className="product-subtitle">
                        {product?.category?.name}
                      </div>
                      <div className="product-title">{product?.name}</div>
                      <div className="product-price">
                        {getFormattedNumber({ value: product?.price })}
                      </div>
                    </div>
                    <div className="product-action">
                      <button className="btn btn-primary">
                        Ajouter au panier
                      </button>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : null}

      <Comments
        product={product}
        comments={values?.comments}
        updateComments={updateComments}
      />
    </>
  );
}
