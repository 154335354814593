import React from "react";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";

export default function DeleteShop({ shop, onRemove }) {
  const api = useApi();
  const flash = useFlash();

  const handleClickRemove = async () => {
    const isOk = window.confirm(
      `confirmez pour supprimer votre boutique ${shop.name}`
    );
    if (isOk) {
      const response = await api.delete(`/api/shops/${shop._id}`);
      if (response.success) {
        onRemove(response.shop);
      } else {
        flash(response.error.message, "danger");
      }
    }
  };

  return (
    <button className="btn" onClick={handleClickRemove}>
      <i class="bi bi-trash-fill fs-4"></i>
    </button>
  );
}
