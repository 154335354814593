import React, { useEffect, useState } from "react";
import { useApi } from "../core/contexts/ApiProvider";
import { Link } from "react-router-dom";
import Spinner from "../core/components/Spinner";
import { useUser } from "../core/contexts/UserProvider";
import DeleteShop from "./DeleteShop";

export default function MyShops() {
  const { user } = useUser();
  const api = useApi();
  const [shops, setShops] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/shops/by/${user._id}`);
      if (response.success) {
        setShops(response.shops);
      }
    })();
  }, [api, user]);

  const removeShop = (shop) => {
    const updatedShops = [...shops];
    const index = updatedShops.indexOf(shop);
    updatedShops.splice(index, 1);
    setShops(updatedShops);
  };

  return (
    <div>
      {shops && shops.length === 0 && <Spinner />}
      <div className="container">
        <div className="d-flex align-items-center justify-content-between py-4">
          <h2>Mes boutiques</h2>
          <div className="d-flex align-items-center justify-content-between">
            <Link
              className="d-flex align-items-center gap-2 btn btn-primary"
              to={`/seller/shops/new`}
            >
              <i class="bi bi-plus-lg"></i>
              <span>CREER UNE BOUTIQUE</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="vstack gap-3">
          {shops?.map((shop, i) => (
            <div key={i} className="hstack gap-3 border-bottom">
              <Link
                className="me-auto hstack w-100 gap-3 py-3 text-decoration-none"
                to={`/seller/shops/${shop._id}`}
              >
                <img
                  className="border object-cover rounded"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  key={shop?.file?.name}
                  src={`${
                    !shop?.file
                      ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                      : `${process.env.REACT_APP_BACKEND_URL}/image/${shop?.file?.name}`
                  }`}
                />
                <div>
                  <h3 className="text-body-primary">{shop.name}</h3>
                  <p className="text-muted">{shop.description}</p>
                </div>
              </Link>
              <div className="hstack gap-4">
                <Link to={`/seller/orders/${shop?._id}`}>
                  <i class="bi bi-cart-fill fs-4"></i>
                </Link>
                <Link to={`/seller/shops/${shop?._id}/edit`}>
                  <i class="bi bi-pencil-fill fs-4"></i>
                </Link>
                <DeleteShop shop={shop} onRemove={removeShop} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
