import React, { useState } from "react";
import { useUser } from "../core/contexts/UserProvider";
import { useApi } from "../core/contexts/ApiProvider";
import { useNavigate } from "react-router-dom";
import { useFlash } from "../core/contexts/FlashProvider";
import { formatTimeAgo } from "../core/helpers/Utility";

export default function Comments({ product, comments, updateComments }) {
  const navigate = useNavigate();
  const flash = useFlash();
  const api = useApi();
  const { user } = useUser();
  const [text, setText] = useState();

  const addComment = async (event) => {
    if (event.keyCode === 13 && event.target.value) {
      if (!user) {
        flash("Connectez vous pour commenter", "warning");
        navigate("/signin");
      }
      const response = await api.put(`/api/products/comment/${product._id}`, {
        user: user._id,
        text,
      });
      if (response.success) {
        setText("");
        updateComments(response.product.comments);
      }
    }
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const deleteComment = async (comment) => {
    const response = await api.put(
      `/api/products/uncomment/${product._id}`,
      comment
    );
    if (response.success) {
      updateComments(response.product.comments);
    }
  };

  return (
    <section>
      <div className="container my-4">
        <h2 className="py-4">{comments?.length} Commentaires</h2>
        <div className="d-flex align-items-center gap-3 mb-5">
          <img
            className="border object-cover rounded-circle"
            style={{ width: "60px", height: "60px", objectFit: "cover" }}
            key={user?.profilePicture}
            src={`${
              !user?.profilePicture
                ? `${process.env.PUBLIC_URL}/assets/profile.png`
                : `${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`
            }`}
          />
          <input
            className="form-control border-b"
            type="text"
            placeholder="Commenter"
            onKeyDown={addComment}
            onChange={handleChange}
            value={text}
          />
        </div>
        {comments?.length > 0 ? (
          <div className="my-2">
            {comments?.map((comment, i) => (
              <div key={i}>
                <div className="hstack gap-3 align-items-start">
                  <img
                    className="border rounded-circle"
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                    src={`${
                      !comment?.user?.profilePicture
                        ? `${process.env.PUBLIC_URL}/assets/profile.png`
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${comment?.user?.profilePicture}`
                    }`}
                  />
                  <div className="vstack gap-2">
                    <small className="text-muted">
                      {comment?.user?.firstName + " " + comment?.user?.lastName}{" "}
                      ,{formatTimeAgo(comment?.created)}
                    </small>
                    <p>{comment?.text}</p>
                  </div>
                  <div>
                    {user?._id === comment?.user?._id && (
                      <button
                        className="btn btn-light"
                        onClick={() => deleteComment(comment)}
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="p-4 card">
            <div className="card-body">
              <h5 className="text-center">0 commentaires</h5>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
