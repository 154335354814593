import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer class="footer pt-5">
      <div class="container">
        <div className="row">
          <div className="col-lg-3 col-sm-1">
            <h3 className="footer-heading text-black">Obtenir de l'aide</h3>
            <ul className="footer-nav">
              <li>
                <Link to="">Centre d'aide</Link>
              </li>
              <li>
                <Link to="">Discussion en directe</Link>
              </li>
              <li>
                <Link to="">Vérifier l'etat de la commande</Link>
              </li>
              <li>
                <Link to="">Remboursements</Link>
              </li>
              <li>
                <Link to="">Rapport abuse</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-sm-1">
            <h3 className="footer-heading text-black">
              Approvisionnez-vous sur fatihoune.com
            </h3>
            <ul className="footer-nav">
              <li>
                <Link to="">Centre d'aide</Link>
              </li>
              <li>
                <Link to="">Discussion en directe</Link>
              </li>
              <li>
                <Link to="">Vérifier l'etat de la commande</Link>
              </li>
              <li>
                <Link to="">Remboursements</Link>
              </li>
              <li>
                <Link to="">Rapport abuse</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-1">
            <h3 className="footer-heading text-black">
              Vendre sur fatihoune.com
            </h3>
            <ul className="footer-nav">
              <li>
                <Link to="">Commencer à vendre</Link>
              </li>
              <li>
                <Link to="">Vendeur Central</Link>
              </li>
              <li>
                <Link to="">Devenir un fournisseur vérifié</Link>
              </li>
              <li>
                <Link to="">Partenariats</Link>
              </li>
              <li>
                <Link to="">
                  Téléchargez l'application pour les fournisseurs
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-1">
            <h3 className="footer-heading text-black">
              Apprenez à nous connaître
            </h3>
            <ul className="footer-nav">
              <li>
                <Link to="">Apropos de nous</Link>
              </li>
              <li>
                <Link to="">Responsabilité des entreprises</Link>
              </li>
              <li>
                <Link to="">Centre d'informations</Link>
              </li>
              <li>
                <Link to="">Remboursements</Link>
              </li>
              <li>
                <Link to="">Carrières</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="d-lg-flex justify-content-between">
          <div className="d-flex gap-3">
            <Link>
              <i class="bi bi-facebook"></i>
            </Link>
            <Link>
              <i class="bi bi-linkedin"></i>
            </Link>
            <Link>
              <i class="bi bi-twitter"></i>
            </Link>
            <Link>
              <i class="bi bi-youtube"></i>
            </Link>
            <Link>
              <i class="bi bi-instagram"></i>
            </Link>
            <Link>
              <i class="bi bi-tiktok"></i>
            </Link>
          </div>
          <div className="d-lg-flex gap-3">
            <span className="text-black">
              Vendez en déplacement avec l'application fatihoune.com
            </span>
            <Link>
              <img
                style={{ height: "40px" }}
                src="https://s.alicdn.com/@img/imgextra/i4/O1CN018KnDNq1JleFgkjLRq_!!6000000001069-2-tps-447-132.png"
                alt=""
              />
            </Link>
            <Link>
              <img
                style={{ height: "40px" }}
                src="https://s.alicdn.com/@img/imgextra/i4/O1CN01i9Aj641atkjJJ9I6y_!!6000000003388-2-tps-396-132.png"
                alt="IOS"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="py-5 bg-light">
        <div className="container">
          <div className="group-link text-center">
            <Link>Fatihoune Express</Link>
            {" | "}
            <Link>Fatihoune S.A.R.L</Link>
            {" | "}
            <Link>Fatihoune Pay</Link>
            {" | "}
            <Link>Fatihoune Foods</Link>
          </div>
          <div className="legal-info text-center">
            <Link>Policies and rules</Link> {" . "}
            <Link>Legal Notice</Link> {" . "}
            <Link>Politique de listage des produits</Link> {" . "}
            <Link>Protection de la propriété intellectuelle</Link> {" . "}
            <Link>Politique de confidentialité</Link> {" . "}
            <Link>Conditions d'utilisation</Link> {" . "}
            <Link>Respect de l'intégrité</Link> {" . "}
          </div>
          <div className="legal-terms text-center">
            <span className="copyright">
              &copy; Copyright {new Date().getFullYear().toString()}, Fatihoune
              Corporation
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
