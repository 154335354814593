import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import Search from "../../product/Search";
import { useCart } from "../contexts/CartProvider";
import LocationChange from "./LocationChange";

export default function Menu() {
  const { user, logout } = useUser();
  const cart = useCart();

  const handleLogout = () => {
    const isOk = window.confirm(`vous voulez vous déconnectez ?`);
    if (isOk) {
      logout();
    }
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="logo navbar-brand text-danger" to="/">
            My Fatihoune
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="justify-content-center align-items-center d-none d-lg-flex flex-nowrap"
            style={{ width: "500px" }}
          >
            <Search />
          </div>
          <div
            className="d-flex align-items-center justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav d-flex align-items-center gap-4">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="https://seller.fatihoune.com"
                >
                  Devenir vendeur
                </a>
              </li>
              <li className="nav-item">
                <LocationChange />
              </li>
              <li className="nav-item">
                <NavLink
                  to="/cart"
                  className={({ isActive, isPending }) =>
                    isActive
                      ? "active ms-1 ms-lg-0 me-n1 me-lg-2 text-decoration-none position-relative"
                      : isPending
                      ? "pending ms-1 ms-lg-0 me-n1 me-lg-2 text-decoration-none position-relative"
                      : "ms-1 ms-lg-0 me-n1 me-lg-2 text-decoration-none position-relative"
                  }
                >
                  <i className="bi bi-cart fs-2 text-black"></i>
                  <span className="position-absolute text-sm badge bg-danger">
                    {cart.numCartItem}
                  </span>
                </NavLink>
              </li>
              {!user && (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle border rounded-pill gap-1 hstack shadow-sm"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="bi bi-list fs-4 px-1"></i>
                      <i className="bi bi-person-circle fs-2 px-1"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item" href="#">
                          Enregistrez-vous
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Connectez-vous
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Devenir vendeur
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Aide
                        </a>
                      </li>
                    </ul>
                  </li>
                </>
              )}
              {user && (
                <>
                  <div className="dropdown">
                    <a
                      className="text-decoration-none dropdown-toggle d-flex align-items-center gap-3"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    >
                      <img
                        className="border object-cover rounded-circle"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                        }}
                        key={user?.profilePicture}
                        src={`${
                          !user?.profilePicture
                            ? `${process.env.PUBLIC_URL}/assets/profile.png`
                            : `${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`
                        }`}
                      />
                    </a>

                    <ul className="dropdown-menu dropdown-menu-lg-end">
                      <li>
                        <Link
                          className="dropdown-item"
                          to={`users/${user._id}`}
                        >
                          Mon Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={handleLogout}
                        >
                          Déconnexion
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
