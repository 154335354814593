import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFlash } from "../core/contexts/FlashProvider";
import { useApi } from "../core/contexts/ApiProvider";
import Spinner from "../core/components/Spinner";
import { getFormattedNumber } from "../core/helpers/Utility";
import AddToCart from "../cart/AddToCart";

export default function Products() {
  const location = useLocation();
  const flash = useFlash();
  const api = useApi();
  const [products, setProducts] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/products/search/${location.search}`);
      if (response.success) {
        setProducts(response.products);
      } else {
        flash(response.error.message, "danger");
      }
    })();
  }, [api, flash, location]);

  return (
    <>
      <section>
        <div className="container">
          {!products && <Spinner />}
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 g-3">
            {products?.map((product, i) => (
              <div className="col" key={i}>
                <Link to={`/products/${product?._id}`} className="product">
                  <div className="product-image">
                    <img
                      class="image"
                      alt="sliderImage"
                      src={`${
                        product?.files.length === 0
                          ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                          : `${process.env.REACT_APP_BACKEND_URL}/image/${product?.files[0]?.name}`
                      }`}
                    />
                  </div>
                  <div className="product-description">
                    <div className="product-title">{product?.name}</div>
                    <div className="product-price">
                      {getFormattedNumber({ value: product?.price })}
                    </div>
                  </div>
                </Link>
                <div className="product-action">
                  <AddToCart product={product} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
