import React from "react";
import { useCart } from "../core/contexts/CartProvider";
import { useFlash } from "../core/contexts/FlashProvider";

export default function AddToCart({ product }) {
  const flash = useFlash();
  const cart = useCart();
  const addToCart = () => {
    cart.addItem(product, () => {
      flash("Produit ajouter au panier", "success");
      return false;
    });
  };
  return (
    <div>
      <button className="btn btn-danger hstack gap-3" onClick={addToCart}>
        <span>AJOUTER AU PANIER</span>
        <i class="bi bi-cart-plus fs-4"></i>
      </button>
    </div>
  );
}
