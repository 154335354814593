import React, { useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    search: "",
  });

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const enterKey = (event) => {
    if (event.keyCode == 13) {
      event.preventDefault();
      search();
    }
  };

  const search = async () => {
    const query = queryString.stringify(values);
    navigate(`/?${query}`);
  };

  return (
    <>
      <div class="input-group d-none d-lg-flex flex-nowrap mx-4">
        <input
          class="form-control w-100"
          type="text"
          placeholder="Rechercher produit ..."
          onKeyDown={enterKey}
          onChange={handleChange("search")}
        />
        <button className="btn btn-danger" onClick={() => search()}>
          <i class="bi bi-search"></i>
        </button>
      </div>
    </>
  );
}
