import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./core/Home";
import Signup from "./user/Signup";
import Layout from "./core/components/Layout";
import Activation from "./auth/Activation";
import SetupProfile from "./setup/SetupProfile";
import Signin from "./auth/Signin";
import Profile from "./user/Profile";
import EditProfile from "./user/EditProfile";
import ProtectedRoute from "./core/components/ProtectedRoute";
import Cart from "./cart/Cart";
import Checkout from "./cart/Checkout";
import Order from "./order/Order";
import SingleProduct from "./product/SingleProduct";

export default function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path="products/:productId" element={<SingleProduct />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signin" element={<Signin />} />
        <Route path="activation" element={<Activation />} />
        <Route path="setup-profile" element={<SetupProfile />} />
        <Route path="cart" element={<Cart />} />
        <Route element={<ProtectedRoute />}>
          <Route path="users/:userId" element={<Profile />} />
          <Route path="users/:userId/edit" element={<EditProfile />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/orders/:orderId" element={<Order />} />
        </Route>
      </Route>
    </Routes>
  );
}
