import React from "react";
import Menu from "./Menu";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <Menu />
      <Outlet />
      <Footer />
    </>
  );
}
