import { createContext, useContext, useEffect, useState } from "react";

const CartContext = createContext();

export default function CartProvider({ children }) {
  const [numCartItem, setNumCartItem] = useState(0);

  const itemTotal = () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        return JSON.parse(localStorage.getItem("cart")).length;
      }
    }
    return 0;
  };

  useEffect(() => {
    const num = itemTotal();
    setNumCartItem(num);
  }, []);

  const addItem = (item, cb) => {
    let cart = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      cart.push({
        product: item,
        quantity: 1,
        shop: item.shop._id,
      });
      localStorage.setItem("cart", JSON.stringify(cart));
      const num = itemTotal();
      setNumCartItem(num);
      cb();
    }
  };

  const updateCart = (itemIndex, quantity) => {
    let cart = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      cart[itemIndex].quantity = quantity;
      localStorage.setItem("cart", JSON.stringify(cart));
      const num = itemTotal();
      setNumCartItem(num);
    }
  };

  const getCart = () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        return JSON.parse(localStorage.getItem("cart"));
      }
    }
    return [];
  };

  const removeItem = (itemIndex) => {
    let cart = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      cart.splice(itemIndex, 1);
      localStorage.setItem("cart", JSON.stringify(cart));
      const num = itemTotal();
      setNumCartItem(num);
    }
    return cart;
  };

  const emptyCart = (cb) => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("cart");
      const num = itemTotal();
      setNumCartItem(num);
      cb();
    }
  };

  return (
    <CartContext.Provider
      value={{
        numCartItem,
        itemTotal,
        addItem,
        updateCart,
        getCart,
        removeItem,
        emptyCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}
