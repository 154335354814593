import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Image from "../core/components/Image";
import FilterChange from "../core/components/FilterChange";
import queryString from "query-string";

export default function Categories() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(null);
  const sliderRef = useRef(null);
  const scrollAmount = 200;

  const api = useApi();
  const flash = useFlash();
  const [categories, setCategories] = useState();

  const handleClick = (selected) => {
    setSelected(selected);
    if (selected === null || selected === undefined) {
      navigate(`/`);
    } else {
      navigate(`/?category=${selected._id}`);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    (async () => {
      const response = await api.get(`/api/categories`);
      if (response.success) {
        setCategories(response?.categories);
        if (parsed) {
          const selectedCategories = response?.categories.filter(
            (category) => category._id === parsed.category
          );
          setSelected(selectedCategories[0]);
        }
      } else {
        flash(response.error.message, "danger");
      }
    })();
  }, [api]);

  return (
    <section>
      <div className="container">
        <div className="slider">
          <button
            className="nav-btn left"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft -= scrollAmount;
            }}
          >
            <i className="bi bi-chevron-left"></i>
          </button>
          <div className="images-container" ref={sliderRef}>
            <button
              className={`category rounded-0 btn ${
                selected === null ? "active" : ""
              }`}
              onClick={() => handleClick(null)}
            >
              <div className="image-container">
                <img
                  className="image"
                  alt="sliderImage"
                  src={`${process.env.PUBLIC_URL}/assets/no-image.png`}
                />
              </div>
              <div className="">
                <p className="text-truncate">Tous</p>
              </div>
            </button>
            {categories?.map((item, i) => {
              return (
                <button
                  className={`category rounded-0 btn ${
                    selected?._id === item?._id ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => handleClick(item)}
                >
                  <div className="image-container">
                    <img
                      className="image"
                      alt="sliderImage"
                      src={`${
                        !item?.files
                          ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                          : `${process.env.REACT_APP_BACKEND_URL}/image/${item?.file?.name}`
                      }`}
                    />
                  </div>
                  <div className="">
                    <p className="text-truncate">{item.name}</p>
                  </div>
                </button>
              );
            })}
          </div>
          <button
            className="nav-btn right"
            onClick={() => {
              const container = sliderRef.current;
              container.scrollLeft += scrollAmount;
            }}
          >
            <i className="bi bi-chevron-right"></i>
          </button>
          <FilterChange />
        </div>
      </div>
    </section>
  );
}
