import React, { useState } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { useFlash } from "../core/contexts/FlashProvider";
import { useApi } from "../core/contexts/ApiProvider";
import { useNavigate } from "react-router-dom";
import cart from "./cart-helper";

function PlaceOrder(props) {
  const flash = useFlash();
  const navigate = useNavigate();
  const api = useApi();
  const [values, setValues] = useState({
    order: {},
    error: "",
    redirect: false,
    orderId: "",
  });

  const placeOrder = () => {
    props.stripe.createToken().then(async (payload) => {
      if (payload.error) {
        setValues({ ...values, error: payload.error.message });
      } else {
        console.log(values);
        const response = await api.post(`/api/orders`, {
          order: props.checkoutDetails,
          token: payload.token.id,
        });
        if (response.success) {
          cart.emptyCart(() => {
            navigate(`/orders/${response.order._id}`);
          });
        } else {
          flash(response.error.message, "danger");
        }
      }
    });
  };

  return (
    <div>
      <h5>Details de la carte</h5>
      <div className=" mb-3">
        <CardElement className="form-control" />
      </div>
      <button className="btn btn-primary" onClick={placeOrder}>
        Commander
      </button>
    </div>
  );
}

export default injectStripe(PlaceOrder);
