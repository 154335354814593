import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./MainRouter";
import FlashProvider from "./core/contexts/FlashProvider";
import ApiProvider from "./core/contexts/ApiProvider";
import UserProvider from "./core/contexts/UserProvider";
import FlashMessage from "./core/components/FlashMessage";
import CartProvider from "./core/contexts/CartProvider";
import ScrollToTop from "./core/components/ScrollToTop";

export default function App() {
  return (
    <BrowserRouter>
      <FlashProvider>
        <ApiProvider>
          <UserProvider>
            <CartProvider>
              <FlashMessage />
              <ScrollToTop />
              <MainRouter />
            </CartProvider>
          </UserProvider>
        </ApiProvider>
      </FlashProvider>
    </BrowserRouter>
  );
}
