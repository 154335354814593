import React, { useEffect, useState } from "react";
import { useUser } from "../core/contexts/UserProvider";
import { useApi } from "../core/contexts/ApiProvider";
import { Link } from "react-router-dom";
import Spinner from "../core/components/Spinner";

export default function MyOrders() {
  const { user } = useUser();
  const api = useApi();
  const [orders, setOrders] = useState();

  useEffect(() => {
    if (user) {
      (async () => {
        const response = await api.get(`/api/orders/user/${user?._id}`);
        if (response.success) {
          setOrders(response.orders);
        }
      })();
    }
  }, [api, user]);
  return (
    <>
      {!orders && <Spinner />}
      {orders && orders.length > 0 && (
        <div className="my-4">
          <h5 className="mb-3">Mes commandes</h5>
          <div>
            {orders?.map((order, i) => (
              <Link
                to={`/orders/${order?._id}`}
                className="vstack gap-3 text-decoration-none justify-content-center"
              >
                <div key={i}># {order._id}</div>
                <p>{new Date(order.createdAt).toDateString()}</p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
