import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Aos from "aos";

import "./index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";

ReactDOM.render(<App />, document.getElementById("root"));

(() => {
  const options = {
    duration: 700,
    easing: "ease-out-quad",
    once: true,
    startEvent: "load",
    disable: "mobile",
  };
  Aos.init(options);
})();
