import React, { useState } from "react";
import CartItems from "./CartItems";
import Checkout from "./Checkout";
import { StripeProvider } from "react-stripe-elements";
import { Link } from "react-router-dom";

export default function Cart() {
  const [checkout, setCheckout] = useState(false);

  const showCheckout = (val) => {
    setCheckout(val);
  };
  return (
    <>
      <div className="py-4">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to={`/`}>Home</Link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Panier
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <header>
          <h3 className="py-2">Mon panier</h3>
        </header>
        <main>
          <CartItems checkout={checkout} setCheckout={showCheckout} />
          {checkout && (
            <div>
              <StripeProvider
                apiKey={process.env.REACT_APP_STRIPE_TEST_API_KEY}
              >
                <Checkout />
              </StripeProvider>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
