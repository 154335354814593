import React, { useState } from "react";
import { useUser } from "../core/contexts/UserProvider";
import cart from "./cart-helper";
import { Elements } from "react-stripe-elements";
import PlaceOrder from "./PlaceOrder";

export default function Checkout() {
  const { user } = useUser();
  const [values, setValues] = useState({
    checkoutDetails: {
      products: cart.getCart(),
      customer_name: user?.firstName + " " + user?.lastName,
      customer_email: user?.email,
      delivery_address: {
        street: "",
        city: "",
        state: "",
        zipcode: "",
        country: "",
      },
    },
    error: "",
  });

  const handleCustomerChange = (name) => (event) => {
    let checkoutDetails = values.checkoutDetails;
    checkoutDetails[name] = event.target.value || undefined;
    setValues({ ...values, checkoutDetails: checkoutDetails });
  };

  const handleAddressChange = (name) => (event) => {
    let checkoutDetails = values.checkoutDetails;
    checkoutDetails.delivery_address[name] = event.target.value || undefined;
    setValues({ ...values, checkoutDetails: checkoutDetails });
  };
  return (
    <div>
      <h2>Commander</h2>
      <input
        id="name"
        placeholder="Name"
        className=""
        value={values.checkoutDetails.customer_name}
        onChange={handleCustomerChange("customer_name")}
        margin="normal"
      />
      <br />
      <input
        id="email"
        type="email"
        placeholder="Email"
        className=""
        value={values.checkoutDetails.customer_email}
        onChange={handleCustomerChange("customer_email")}
        margin="normal"
      />
      <br />
      <h5>Delivery Address</h5>
      <input
        id="street"
        placeholder="Street Address"
        className=""
        value={values.checkoutDetails.delivery_address.street}
        onChange={handleAddressChange("street")}
        margin="normal"
      />
      <br />
      <input
        id="city"
        placeholder="City"
        className=""
        value={values.checkoutDetails.delivery_address.city}
        onChange={handleAddressChange("city")}
        margin="normal"
      />
      <input
        id="state"
        placeholder="State"
        className=""
        value={values.checkoutDetails.delivery_address.state}
        onChange={handleAddressChange("state")}
        margin="normal"
      />
      <br />
      <input
        id="zipcode"
        placeholder="Zip Code"
        className=""
        value={values.checkoutDetails.delivery_address.zipcode}
        onChange={handleAddressChange("zipcode")}
        margin="normal"
      />
      <input
        id="country"
        placeholder="Country"
        className=""
        value={values.checkoutDetails.delivery_address.country}
        onChange={handleAddressChange("country")}
        margin="normal"
      />
      <br /> {values.error && <p className="text-danger">{values.error}</p>}
      <div>
        <Elements>
          <PlaceOrder checkoutDetails={values.checkoutDetails} />
        </Elements>
      </div>
    </div>
  );
}
