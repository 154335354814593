import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";
import Spinner from "../core/components/Spinner";

export default function Order() {
  const api = useApi();
  const flash = useFlash();
  const { orderId } = useParams();
  const [order, setOrder] = useState({ products: [], delivery_address: {} });

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/orders/${orderId}`);
      if (response.success) {
        setOrder(response.order);
      } else {
        flash(response.error.message, "danger");
      }
    })();
  }, []);

  const getTotal = () => {
    return order.products.reduce((a, b) => {
      const quantity = b.status == "Cancelled" ? 0 : b.quantity;
      return a + quantity * b.product.price;
    }, 0);
  };

  return (
    <>
      {!order && <Spinner />}
      {order && (
        <div className="container">
          <header className="my-3">
            <div className="hstack align-items-center">
              <div>
                <h2>Detail de la commande</h2>
                <p>
                  Code: <strong>{order._id}</strong> <br /> Placed on{" "}
                  {new Date(order.createdAt).toDateString()}
                </p>
              </div>
              <div className="ms-auto justify-content-end">
                <h3 className="text-danger">{getTotal() + "F.CFA"}</h3>
              </div>
            </div>
          </header>
          <main>
            <div className="row">
              <div className="col-9">
                {order.products.map((item, i) => (
                  <div className="hstack gap-3 mb-3" key={i}>
                    <img
                      className="border object-cover rounded"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      key={item?.product?.file?.name}
                      src={`${
                        !item?.product?.file
                          ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                          : `${process.env.REACT_APP_BACKEND_URL}/image/${item?.product?.file?.name}`
                      }`}
                    />
                    <div>
                      <h3 className="text-body-primary">
                        {item?.product?.name}
                      </h3>
                      <p className="text-muted">QTé {item?.quantity}</p>
                      <p className="text-muted">{item?.product?.price} F.CFA</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-3">
                <div className="card">
                  <div className="card-body">
                    <h4>Delivré à :</h4>
                    <div className="hstack gap-3 align-items-center">
                      <h5 className="m-0">Client:</h5>
                      <p className="m-0">
                        {order.customer_name} {order.customer_email}
                      </p>
                    </div>
                    <div className="hstack gap-3 align-items-center">
                      <h5 className="m-0">Adresse de livraison:</h5>
                      <p className="m-0">{order.delivery_address.street}</p>
                    </div>
                    <div className="hstack gap-3 align-items-center">
                      <h5 className="m-0">Ville:</h5>
                      <p className="m-0">{order.delivery_address.city}</p>
                    </div>
                    <div className="hstack gap-3 align-items-center">
                      <h5 className="m-0">Pays:</h5>
                      <p className="m-0">{order.delivery_address.country}</p>
                    </div>
                    <div className="hstack gap-3 align-items-center">
                      <h5 className="m-0">Zip Code:</h5>
                      <p className="m-0">{order.delivery_address.zipcode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}
