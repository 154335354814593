import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";

const Schema = Yup.object().shape({
  firstName: Yup.string().required("Nom requis!"),
  lastName: Yup.string().required("Prénoms requis!"),
});

export default function EditProfile() {
  const navigate = useNavigate();
  const params = useParams();
  const flash = useFlash();
  const api = useApi();
  const [user, setUser] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/users/${params.userId}`);
      setUser(response);
      setImage(response.profilePicture);
    })();
  }, [api, params]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profilePicture: user?.profilePicture,
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      const response = await api.put(`/api/users/${params.userId}`, values);
      if (response.success) {
        flash(`Profile modifié effectué`, `success`);
        navigate(`/users/${params.userId}`);
      } else {
        flash(`${response.message}`, `danger`);
      }
    },
  });

  const handleFileChange = async (event) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      formik.setFieldValue("profilePicture", response.data.name);
      setImage(response.data.name);
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center my-4">
          <form onSubmit={formik.handleSubmit}>
            <h2>Modifiez votre profile</h2>
            <div className="row">
              <div className="col-9">
                <div className="form-floating mb-3">
                  <input
                    className="form-control outline-none"
                    id="firstName"
                    placeholder="name@example.com"
                    name="firstName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                  <label htmlFor="firstName">Nom</label>
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <div>{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Password"
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                  />
                  <label htmlFor="lastName">Prénoms</label>
                  {formik.errors.lastName && formik.touched.lastName ? (
                    <div>{formik.errors.lastName}</div>
                  ) : null}
                </div>
                <div className="my-3 d-grid">
                  <button type="submit" className="btn btn-primary btn-lg">
                    {formik.isSubmitting ? "...." : "Enregistrer"}
                  </button>
                </div>
              </div>
              <div className="col-3">
                <label htmlFor="file">
                  <img
                    className="border rounded"
                    src={`${
                      !image
                        ? `${process.env.PUBLIC_URL}/assets/profile.png`
                        : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                    }`}
                    alt="profile"
                    style={{
                      width: "80px",
                      height: "80px",
                      cursor: "pointer",
                      objectFit: "cover",
                    }}
                  />
                  <input
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    id="file"
                    name="file"
                  />
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
